var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"block"},[_c('b-radio',{attrs:{"name":"personal","native-value":"personal"},model:{value:(_vm.formTaxAddress.note),callback:function ($$v) {_vm.$set(_vm.formTaxAddress, "note", $$v)},expression:"formTaxAddress.note"}},[_vm._v(" บุคคลธรรมดา ")]),_c('b-radio',{attrs:{"name":"corporation","native-value":"corporation"},model:{value:(_vm.formTaxAddress.note),callback:function ($$v) {_vm.$set(_vm.formTaxAddress, "note", $$v)},expression:"formTaxAddress.note"}},[_vm._v(" นิติบุคคล ")])],1)]),(!_vm.isCorporation)?_c('div',{staticClass:"column is-12"},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"คำนำหน้าชื่อ","type":errors[0] ? 'is-danger' : 'is-dark',"message":errors[0]}},[_c('b-input',{attrs:{"label":"คำนำหน้าชื่อ *","dense":""},model:{value:(_vm.formTaxAddress.title),callback:function ($$v) {_vm.$set(_vm.formTaxAddress, "title", $$v)},expression:"formTaxAddress.title"}})],1)]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"column is-12"},[_c('validation-provider',{attrs:{"name":"Name","rules":_vm.textFieldRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors[0] ? 'is-danger' : 'is-dark',"message":errors[0],"label":_vm.labelName}},[_c('b-input',{attrs:{"label":_vm.labelName,"dense":""},model:{value:(_vm.formTaxAddress.firstName),callback:function ($$v) {_vm.$set(_vm.formTaxAddress, "firstName", $$v)},expression:"formTaxAddress.firstName"}})],1)]}}],null,true)})],1),(!_vm.isCorporation)?_c('div',{staticClass:"column is-12"},[_c('validation-provider',{attrs:{"name":"Lastname","rules":_vm.textFieldRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"นามสกุล *","type":errors[0] ? 'is-danger' : 'is-dark',"message":errors[0]}},[_c('b-input',{attrs:{"label":"นามสกุล *","dense":""},model:{value:(_vm.formTaxAddress.lastName),callback:function ($$v) {_vm.$set(_vm.formTaxAddress, "lastName", $$v)},expression:"formTaxAddress.lastName"}})],1)]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"column is-12"},[_c('validation-provider',{attrs:{"name":"Taxid","rules":_vm.textFieldRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"เลขประจำตัวผู้เสียภาษี *","type":errors[0] ? 'is-danger' : 'is-dark',"message":errors[0]}},[_c('b-input',{directives:[{name:"mask",rawName:"v-mask",value:('#-####-#####-##-#'),expression:"'#-####-#####-##-#'"}],attrs:{"label":"เลขประจำตัวผู้เสียภาษี *","return-masked-value":"","dense":""},model:{value:(_vm.formTaxAddress.taxId),callback:function ($$v) {_vm.$set(_vm.formTaxAddress, "taxId", $$v)},expression:"formTaxAddress.taxId"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('validation-provider',{attrs:{"name":"Address","rules":_vm.textFieldRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"ที่อยู่ 1 *","type":errors[0] ? 'is-danger' : 'is-dark',"message":errors[0]}},[_c('b-input',{attrs:{"label":"ที่อยู่ 1 *","dense":""},model:{value:(_vm.formTaxAddress.address),callback:function ($$v) {_vm.$set(_vm.formTaxAddress, "address", $$v)},expression:"formTaxAddress.address"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('validation-provider',{attrs:{"name":"Address2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"ที่อยู่ 2","type":errors[0] ? 'is-danger' : 'is-dark',"message":errors[0]}},[_c('b-input',{attrs:{"label":"ที่อยู่ 2","dense":""},model:{value:(_vm.formTaxAddress.address2),callback:function ($$v) {_vm.$set(_vm.formTaxAddress, "address2", $$v)},expression:"formTaxAddress.address2"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-6"},[_c('validation-provider',{attrs:{"name":"Subdistrict","rules":_vm.textFieldRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('b-field',{attrs:{"label":"แขวง/ตำบล *","type":errors[0] ? 'is-danger' : 'is-dark',"message":errors[0]}},[_c('b-input',{attrs:{"color":"secondary","dense":""},on:{"blur":validate},model:{value:(_vm.formTaxAddress.states),callback:function ($$v) {_vm.$set(_vm.formTaxAddress, "states", $$v)},expression:"formTaxAddress.states"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-6"},[_c('validation-provider',{attrs:{"name":"District","rules":_vm.textFieldRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('b-field',{attrs:{"label":"เขต/อำเภอ *","type":errors[0] ? 'is-danger' : 'is-dark',"message":errors[0]}},[_c('b-input',{attrs:{"color":"secondary","dense":""},on:{"blur":validate},model:{value:(_vm.formTaxAddress.district),callback:function ($$v) {_vm.$set(_vm.formTaxAddress, "district", $$v)},expression:"formTaxAddress.district"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-6"},[_c('validation-provider',{attrs:{"name":"Province","rules":_vm.textFieldRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('b-field',{attrs:{"label":"จังหวัด *","type":errors[0] ? 'is-danger' : 'is-dark',"message":errors[0]}},[_c('b-input',{attrs:{"color":"secondary","dense":""},on:{"blur":validate},model:{value:(_vm.formTaxAddress.province),callback:function ($$v) {_vm.$set(_vm.formTaxAddress, "province", $$v)},expression:"formTaxAddress.province"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-6"},[_c('validation-provider',{attrs:{"name":"Postcode","rules":_vm.textFieldRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('b-field',{attrs:{"label":"รหัสไปรษณีย์ *","type":errors[0] ? 'is-danger' : 'is-dark',"message":errors[0]}},[_c('b-input',{directives:[{name:"mask",rawName:"v-mask",value:('#####'),expression:"'#####'"}],attrs:{"color":"secondary","type":"postcode","dense":""},on:{"blur":validate},model:{value:(_vm.formTaxAddress.postcode),callback:function ($$v) {_vm.$set(_vm.formTaxAddress, "postcode", $$v)},expression:"formTaxAddress.postcode"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('validation-provider',{attrs:{"name":"contact","rules":_vm.textFieldRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"เบอร์โทรศัพท์ *","type":errors[0] ? 'is-danger' : 'is-dark',"message":errors[0]}},[_c('b-input',{attrs:{"label":"เบอร์โทรศัพท์ *","dense":""},model:{value:(_vm.formTaxAddress.contactNo),callback:function ($$v) {_vm.$set(_vm.formTaxAddress, "contactNo", $$v)},expression:"formTaxAddress.contactNo"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('b-button',{staticClass:"mr-4",attrs:{"loading":_vm.loading,"type":"is-dark"},on:{"click":function($event){return handleSubmit(_vm.onSubmit)}}},[_vm._v(" SAVE ")]),_c('b-button',{attrs:{"loading":_vm.loading,"type":"is-dark"},on:{"click":_vm.onCancel}},[_vm._v(" CANCEL ")])],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }