






















































































































































import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import OrderProvider from '@/resources/order.provider'
import Printer from '@/libs/imin-printer.esm.browser.min'
import html2canvas from 'html2canvas'
import MemberProvider from '@/resources/member.provider'
import ItemAddress from '@/components/tax-invoice/item-address.vue'
import MemberAddress from '@/components/tax-invoice/member-address.vue'
import Receipt from '@/components/Receipt.vue'
import TaxInfoForm from '@/components/tax-invoice/form.vue'
import TaxInvoice from '@/components/tax-invoice.vue'
import DesktopPosNavbar from '@/components/desktop-pos/navbar.vue'

@Component({
  components: {
    ItemAddress,
    MemberAddress,
    Receipt,
    TaxInfoForm,
    TaxInvoice,
    DesktopPosNavbar
  },
  computed: {
    ...mapState({
      selectBranch: (state: any) => state.Store.selectBranch,
      storeInfo: (state: any) => state.Store.storeInfo,
      selectPos: (state: any) => state.Store.selectPos
    }),
    ...mapGetters({
      branchAddress: 'Store/branchAddress'
    })
  }
})
export default class TaxInvoices extends Vue {
  orderProvider = new OrderProvider()

  memberService = new MemberProvider()

  branchAddress: any

  memberData: any = null

  addresses: any = []

  base64Url = ''

  selectBranch!: any;

  selectPos!: any;

  storeInfo!: any;

  loading = false;

  onSaveLoading = false;

  bill: any = null

  billId = ''

  status = ''

  printLoading = false

  formValue: any = null

  isEditing = false

  isAdd = false

  indexOfEditAddress: any = null

  selectedMemberAddress: any = {
    isDefault: true
  }

  setDefault (): void {
      this.bill = null
      this.addresses = []
      this.status = ''
      this.selectedMemberAddress = {
        isDefault: true
      }
      this.isEditing = false
      this.isAdd = false
      this.formValue = null
      this.onSaveLoading = false
      this.memberData = null
  }

  async fetchBills (): Promise<void> {
    try {
      this.loading = true
      this.setDefault()
      const upperCaseKeyword = this.billId.toUpperCase()
      const { data } = await this.orderProvider.fetchBillingByOrderId(upperCaseKeyword)
      if (data && data.member) {
        this.fetchMember(data.member.id)
        data.member = {
          isDefault: true
        }
        this.bill = data
      } else {
        this.showErrorAlert()
      }
    } catch (error) {
        this.showErrorAlert()
    } finally {
        this.loading = false
    }
  }

  showErrorAlert (): void {
    this.$buefy.dialog.alert({
        title: 'ไม่พบใบเสร็จที่ค้นหา',
        message: `ไม่พบใบเสร็จเลขที่ <b>"${this.billId}"</b> กรุณาตรวจสอบเลขที่ใบเสร็จอีกครั้ง`,
        confirmText: 'ปิด',
        type: 'is-danger',
        hasIcon: true
    })
  }

  async fetchMember (memberId: any): Promise<any> {
    const { data } = await this.memberService.getMemberById(memberId)
    if (data) {
      this.memberData = data
      if (data.addresses[0]) {
        this.addresses = data.addresses.map((ad: any) => ({ ...ad, selected: false }))
      }
    }
  }

  selectAddress (address: any, index: number): void {
    const oldSelectAddress = this.addresses.findIndex((adr: any) => adr.selected === true)
    if (oldSelectAddress !== -1) this.addresses[oldSelectAddress].selected = false
    this.addresses[index].selected = true
    this.selectedMemberAddress = { ...address, isDefault: false }
    this.bill = { ...this.bill, member: address }
  }

  async updateTaxAddress (addressData: any): Promise<void> {
    this.onSaveLoading = true
    this.memberData.addresses[this.indexOfEditAddress] = addressData
    this.addresses[this.indexOfEditAddress] = { ...addressData, selected: false }
    if (this.memberData?.id) await this.memberService.updateMemberAddress(this.memberData.id, this.memberData.addresses)
    this.selectAddress(addressData, this.indexOfEditAddress)
    this.isEditing = false
    this.formValue = null
    this.onSaveLoading = false
  }

  async addTaxAddress (addressData: any): Promise<void> {
    this.onSaveLoading = true
    this.memberData.addresses.push(addressData)
    this.addresses.push({ ...addressData, selected: false })
    if (this.memberData?.id) await this.memberService.updateMemberAddress(this.memberData.id, this.memberData.addresses)
    this.selectAddress(addressData, this.addresses.length - 1)
    this.isAdd = false
    this.formValue = null
    this.onSaveLoading = false
  }

  async removeTaxAddress (index: number): Promise<void> {
    this.$buefy.dialog.confirm({
      title: 'ต้องการลบที่อยู่หรือไม่',
      message: 'ต้องการลบที่อยู่นี้หรือไม่',
      confirmText: 'ต้องการ',
      cancelText: 'ยกเลิก',
      onConfirm: async () => {
        this.onSaveLoading = true
        this.addresses.splice(index, 1)
        this.memberData.addresses.splice(index, 1)
        if (this.memberData?.id) await this.memberService.updateMemberAddress(this.memberData.id, this.memberData.addresses)
        this.selectedMemberAddress = {
          isDefault: true
        }
        this.onSaveLoading = false
      },
      type: 'is-dark',
      hasIcon: true
    })
  }

  get storeName (): string {
    return this.storeInfo?.name || 'STORE NOT FOUND'
  }

  get warehouseName (): string {
    if (!this.selectBranch || this.selectBranch === null) {
      return ''
    }
    return this.selectBranch.warehouse.name
  }

  async localPrint (): Promise<void> {
    // this.printLoading = true
    const printer = new Printer()
    try {
        const connected = await printer.connect()
        if (connected) {
            this.status = 'กำลังเชื่อมต่อกับเครื่องปรินท์'
            const el: HTMLElement | any = this.$refs.receiptTemplate
            const elementToConvert = await html2canvas(el.$el, {
              scale: 1
            })
            if (elementToConvert) {
              this.base64Url = elementToConvert.toDataURL('image/png')
              this.status = 'กำลังเตรียมข้อมูลเพื่อปรินท์'
            } else {
              this.status = 'การเตรียมข้อมูลเพื่อปรินท์ผิดพลาด'
            }
            printer.initPrinter()
            if (this.base64Url) {
                await printer.printSingleBitmap(this.base64Url, 53)
                await printer.printAndFeedPaper(100)
                await printer.partialCut()
                this.status = 'กำลังทำการปรินท์'
                setTimeout(() => {
                  this.status = 'ปรินท์เสร็จสิ้น'
                }, 5000)
            } else {
              this.status = 'การเตรียมข้อมูลเพื่อปรินท์ผิดพลาด'
            }
        } else {
            this.status = 'ไม่สามารถเชื่อมต่อกับเครื่องปรินท์ได้'
        }
    } catch (error: any) {
        this.status = 'เกิดข้อผิดพลาดกรุณารีเฟรชหรือกดพิมพ์ใบเสร็จอีกครั้ง'
    }
    // this.printLoading = false
  }

  onEditClick (address: any, index: number): void {
    this.indexOfEditAddress = index
    this.formValue = { ...address }
    this.isEditing = true
  }

  onCancel (): void {
    this.isAdd = false
    this.isEditing = false
    this.formValue = null
  }

  async manualCut (): Promise<void> {
    const printer = new Printer()
    try {
        const connected = await printer.connect()
        if (connected) {
          await printer.printAndFeedPaper(100)
          await printer.partialCut()
          this.status = ''
        }
    } catch (error: any) {
        this.status = 'เกิดข้อผิดพลาดกรุณารีเฟรชหรือกดพิมพ์ใบเสร็จอีกครั้ง'
    }
  }
}
