











































































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class MemberAddress extends Vue {
  @Prop()
  memberAddress: any

  @Prop()
  indexOfAddress: any

  @Prop()
  loading: any

  // eslint-disable-next-line
  mapFullName (member: any): string {
      return (member.title || member.firstName || member.lastName) ? `${member.title} ${member.firstName} ${member.lastName}` : '-'
  }

  selectAddress (): void {
      this.$emit('selectAddress', this.memberAddress, this.indexOfAddress)
  }

  onEditClick (): void {
      this.$emit('onEditClick', this.memberAddress, this.indexOfAddress)
  }

  private onRemove (): void {
    this.$emit('remove', this.indexOfAddress)
  }
}
